"use client";
import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react";

import { GlobalStateProvider } from "@/context/globalState";

export default function Providers({
  children,
  isEnabled,
}: {
  children: React.ReactNode;
  isEnabled: boolean;
}) {
  return (
    <GlobalStateProvider>
      <ContentfulLivePreviewProvider
        locale="en-US"
        enableInspectorMode={true}
        enableLiveUpdates={true}
        debugMode={isEnabled}
      >
        {children}
      </ContentfulLivePreviewProvider>
    </GlobalStateProvider>
  );
}
