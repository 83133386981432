"use client";

import { useSearchParams } from "next/navigation";

import { getCookie } from "cookies-next";
import { StatsigProvider, StatsigUser } from "statsig-react";

import { setExperimentOverrideCookiesApp } from "shared-lib/helpers/cookieFunctions";
import { PRODUCTION_BUILD } from "shared-lib/helpers/envFunctions";

export default function ClientApp({
  user,
  children,
}: {
  user: StatsigUser;
  children: any;
}): JSX.Element {
  const params = useSearchParams();

  // Statsig overrides
  setExperimentOverrideCookiesApp(params);

  const md5Query = params.get("md5") || "";

  const md5 = getCookie("md5Hash") || "";
  const userID = getCookie("userID") || "";
  if ((md5Query || md5) && userID !== md5Query && userID !== md5) {
    user.userID = md5Query || md5;
  }

  return (
    <StatsigProvider
      sdkKey={process.env.NEXT_PUBLIC_STATSIG_CLIENT_SDK_KEY!}
      waitForInitialization
      user={user}
      options={{
        environment: {
          tier: PRODUCTION_BUILD
            ? "production"
            : process.env.NODE_ENV === "development"
              ? "development"
              : "staging",
        },
      }}
    >
      {children}
    </StatsigProvider>
  );
}
